exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aboutus-js": () => import("./../../../src/pages/aboutus.js" /* webpackChunkName: "component---src-pages-aboutus-js" */),
  "component---src-pages-contactus-js": () => import("./../../../src/pages/contactus.js" /* webpackChunkName: "component---src-pages-contactus-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-services-mdx-frontmatter-slug-js-content-file-path-servicesdata-bathroom-index-mdx": () => import("./../../../src/pages/services/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/servicesdata/bathroom/index.mdx" /* webpackChunkName: "component---src-pages-services-mdx-frontmatter-slug-js-content-file-path-servicesdata-bathroom-index-mdx" */),
  "component---src-pages-services-mdx-frontmatter-slug-js-content-file-path-servicesdata-brick-index-mdx": () => import("./../../../src/pages/services/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/servicesdata/brick/index.mdx" /* webpackChunkName: "component---src-pages-services-mdx-frontmatter-slug-js-content-file-path-servicesdata-brick-index-mdx" */),
  "component---src-pages-services-mdx-frontmatter-slug-js-content-file-path-servicesdata-housedecoration-index-mdx": () => import("./../../../src/pages/services/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/servicesdata/housedecoration/index.mdx" /* webpackChunkName: "component---src-pages-services-mdx-frontmatter-slug-js-content-file-path-servicesdata-housedecoration-index-mdx" */),
  "component---src-pages-services-mdx-frontmatter-slug-js-content-file-path-servicesdata-kitchen-index-mdx": () => import("./../../../src/pages/services/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/servicesdata/kitchen/index.mdx" /* webpackChunkName: "component---src-pages-services-mdx-frontmatter-slug-js-content-file-path-servicesdata-kitchen-index-mdx" */),
  "component---src-pages-services-mdx-frontmatter-slug-js-content-file-path-servicesdata-loftconversations-index-mdx": () => import("./../../../src/pages/services/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/servicesdata/loftconversations/index.mdx" /* webpackChunkName: "component---src-pages-services-mdx-frontmatter-slug-js-content-file-path-servicesdata-loftconversations-index-mdx" */),
  "component---src-pages-services-mdx-frontmatter-slug-js-content-file-path-servicesdata-paint-index-mdx": () => import("./../../../src/pages/services/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/servicesdata/paint/index.mdx" /* webpackChunkName: "component---src-pages-services-mdx-frontmatter-slug-js-content-file-path-servicesdata-paint-index-mdx" */),
  "component---src-pages-services-mdx-frontmatter-slug-js-content-file-path-servicesdata-plumbingelectric-index-mdx": () => import("./../../../src/pages/services/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/servicesdata/plumbingelectric/index.mdx" /* webpackChunkName: "component---src-pages-services-mdx-frontmatter-slug-js-content-file-path-servicesdata-plumbingelectric-index-mdx" */),
  "component---src-pages-services-mdx-frontmatter-slug-js-content-file-path-servicesdata-roof-index-mdx": () => import("./../../../src/pages/services/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/servicesdata/roof/index.mdx" /* webpackChunkName: "component---src-pages-services-mdx-frontmatter-slug-js-content-file-path-servicesdata-roof-index-mdx" */),
  "component---src-pages-services-mdx-frontmatter-slug-js-content-file-path-tabsdata-interior-interior-mdx": () => import("./../../../src/pages/services/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/tabsdata/interior/interior.mdx" /* webpackChunkName: "component---src-pages-services-mdx-frontmatter-slug-js-content-file-path-tabsdata-interior-interior-mdx" */)
}

